const clusterToArticleMapping = {
  'cluster-1': 'news',
  'cluster-2': 'purpose',
  'cluster-4': 'contact',
  'cluster-5': 'sponsors',
  'cluster-6': 'board',
  'cluster-7': 'projects'
};

Object.keys(clusterToArticleMapping).forEach(clusterName => {
  const cluster = Array.from(document.getElementsByClassName(clusterName));
  cluster.forEach(element => {
    element.addEventListener('click', () => {
      element.classList.toggle('visible');
      document
        .getElementById(clusterToArticleMapping[clusterName])
        .classList.toggle('visible');
    });
  });
});
